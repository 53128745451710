import { localeChangeAfterLoadSuccess, localeLoadTranslations, localeLoadTranslationsRequest } from './locale/locale.epics';
import { sliderLoadTranslationsRequestEpic, sliderLoadTranslationsEpic, sliderChangeAfterLoadSuccessEpic } from './slider/slider.epics';
import {
    widgetChangeAfterLoadSuccessEpic,
    widgetLoadTranslationsEpic,
    widgetLoadTranslationsRequestEpic
} from "./widget/widget.epics";
import {
    contactFormSubmit
} from "./contact/contact.epics";

export const rootEpic = [
    localeLoadTranslations,
    localeLoadTranslationsRequest,
    localeChangeAfterLoadSuccess,
    sliderLoadTranslationsEpic,
    sliderLoadTranslationsRequestEpic,
    sliderChangeAfterLoadSuccessEpic,
    widgetLoadTranslationsEpic,
    widgetLoadTranslationsRequestEpic,
    widgetChangeAfterLoadSuccessEpic,
    contactFormSubmit,
];