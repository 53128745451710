import * as React from 'react';
import './info.css';
import FlipCard from 'react-flipcard-2';
import {FormattedMessage} from "react-intl";
import { Link } from 'react-router-dom';
import {
    faEllipsisH, faEnvelopeSquare
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface InternalState {
    isFlipped: boolean;
}

interface ConnectedState {

}

class InfoComponentInner extends React.PureComponent<ConnectedState, InternalState> {

    constructor(props: ConnectedState) {
        super(props);

        this.state = {
            isFlipped: false
        }

        this.showBack = this.showBack.bind(this)
        this.showFront = this.showFront.bind(this)
        this.handleOnFlip = this.handleOnFlip.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    showBack() {
        this.setState({
            isFlipped: true
        });
    }

    handleOnFlip(flipped) {
        if (flipped) {
            // this.refs.backButton.getDOMNode().focus();

        }
    }

    showFront() {
        this.setState({
            isFlipped: false
        });
    }

    handleKeyDown(e) {
        if (this.state.isFlipped && e.keyCode === 27) {
            this.showFront();
        }
    }

    public render() {
        return (
            <div className={'info-container'}>
                <div className={'box'}>
                    <FlipCard type={'vertical'}
                              disabled={true}
                              flipped={this.state.isFlipped}
                              onFlip={this.handleOnFlip}
                              onKeyDown={this.handleKeyDown}>
                        <div>
                            <div className={'title'}>
                                <h3>Why us</h3>
                            </div>
                            <div className={'description'}>
                                <ul>
                                    <li><b>We Strive to GO Extra Mile.</b></li>
                                    <li>We consider you as our business partner, not a client</li>
                                    <li>We are dedicated, accountable, and provide personal service for your freight needs.</li>
                                    <li>Our dispatchers are certified and well trained to negotiate the best current market rates on all loads.</li>
                                    <li>We make sure that our carriers get paid for every load they haul.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className={'title'}>
                                <h3>Key points</h3>
                            </div>
                            <div className={'description'}>
                                <h3>Learn more about</h3>
                                <Link to="/services" className={'t-dispatch-button-secondary'}>
                                    <FormattedMessage id='page.button.view-services'/>
                                </Link>
                            </div>
                        </div>
                    </FlipCard>
                </div>
                <div className={'box second'}>
                    <FlipCard type={'vertical'}
                              disabled={false}
                              flipped={this.state.isFlipped}
                              onFlip={this.handleOnFlip}
                              onKeyDown={this.handleKeyDown}>
                        <div>
                            <div className={'title'}>
                                <FontAwesomeIcon icon={faEllipsisH} size='3x' />
                            </div>
                            <div className={'description second'}>
                                <h3>Services</h3>
                                <p>
                                    From booking & assigning loads to handling billing, & providing customer support, we do all the hassle so you can focus on growing your business
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className={'title'}>
                                <FontAwesomeIcon icon={faEllipsisH} size='3x' />
                            </div>
                            <div className={'description'}>
                                <Link to="/services" className={'t-dispatch-button-secondary'}>
                                    <FormattedMessage id='page.button.view-services'/>
                                </Link>
                            </div>
                        </div>
                    </FlipCard>
                </div>
                <div className={'section-introduction'}>

                    <h2>T-Dispatch</h2>
                    <p>
                        T-Dispatch is an independent truck dispatch company that provides
                        <span style={{color: '#2495ED', fontWeight: 'bold'}}> excellent dispatch service </span>
                        for owner-operators and small trucking companies.
                        Our truck dispatchers negotiate at the highest rates on your behalf and inform you
                        about your transport options.
                        Our truck dispatch agents will assist you and make the final decision that is
                        the best for you and your truck driver.  We handle all truck paperwork and deal with
                        brokers on your behalf so that you stay focused on expanding your business.
                        We’re not looking for short term clients, we are looking for long term partners because
                        we know that your success is our success. Maximize your earning potential with
                        our dispatch services. <span style={{color: '#2495ED', fontWeight: 'bold'}}>Get Started Today!</span>

                    </p>
                </div>
            </div>
        );
    }
}

export const InfoComponent = InfoComponentInner;