import * as React from "react";
import './two-column-highlight.css';

class TwoColumnHighlight extends React.PureComponent {

    public render() {
        return (
            <div className={'service-highlight-1'}>
                <div className={'image-hover'}>
                    <div className={'column'}>
                        <div className={'heading'}>
                            <div className="dots-bars-7"></div>
                            <h2>
                                Keep Your Fleet Up On The Road
                            </h2>
                        </div>
                    </div>
                    <div className={'column'}>
                        <div className={'description'}>
                            <div className={'bar'}></div>
                            <div className={'text'}>
                                We keep your truck & fleet up & running on the road with high quality dispatch loads that generates you the best  profit. Our prices are reasonable for every budget. No matter what type of dispatch loads you require, we’ve got you covered
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const TwoColumnHighlightComponent = TwoColumnHighlight;

