import { Epic, ofType } from 'redux-observable';
import { map, flatMap, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';

import {
    sliderLoadTranslationsRequest as sliderLoadTranslationsRequestAction,
    sliderLoadTranslationsSuccess as sliderLoadTranslationsSuccessAction,
    sliderChangeTranslations as sliderChangeTranslationsAction,
    SLIDER_LOAD_TRANSLATIONS,
    SLIDER_LOAD_TRANSLATIONS_REQUEST,
    SLIDER_LOAD_TRANSLATIONS_SUCCESS,
} from './slider.actions';
import { messages } from '../../locales/messages';
import { errorNotificationAdd } from '../notification/notification.actions';
import { polyfillIntl } from '../../polyfills/intl.polyfill';

export const sliderLoadTranslationsEpic: Epic =
    (action$) =>
        action$.pipe(ofType(SLIDER_LOAD_TRANSLATIONS),
                map(action => {
                    const locale = action.payload.locale;

                    if (messages.includes(locale)) {
                        return sliderLoadTranslationsRequestAction(locale);
                    }

                    return sliderLoadTranslationsRequestAction('en');
                })
            );

export const sliderChangeAfterLoadSuccessEpic: Epic =
    (action$) =>
        action$.pipe(ofType(SLIDER_LOAD_TRANSLATIONS_SUCCESS),
                mergeMap(({ payload }) =>
                    from(polyfillIntl(payload.locale))
                        .pipe(
                            switchMap(() => [sliderChangeTranslationsAction(payload.locale, payload.contents)])
                        )
                )
            );

export const sliderLoadTranslationsRequestEpic: Epic =
    (action$) =>
        action$.pipe(ofType(SLIDER_LOAD_TRANSLATIONS_REQUEST),
            flatMap(({ payload }) =>
                from(import(`./../../src/slider/slider.${payload.locale}.json`))
                .pipe(
                    mergeMap(importedFile => {

                        if (payload.locale === 'en') {
                            return [sliderLoadTranslationsSuccessAction(payload.locale, importedFile)];
                        }
                        return from(import(`./../../src/slider/slider.en.json`))
                            .pipe(
                                map(defaultFile => {
                                    return sliderLoadTranslationsSuccessAction(payload.locale, Object.assign({}, defaultFile, importedFile));
                                }),
                                catchError(() => {
                                    return [sliderLoadTranslationsSuccessAction(payload.locale, importedFile)];
                                })
                            );
                    }),
                    catchError(() => {
                        return [
                            errorNotificationAdd('Sorry, something went wrong. Please try again later.', {})
                        ];
                    })
                )
            ));
