import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';

async function polyfillGetCanonicalLocales() {
    if (shouldPolyfillGetCanonicalLocales()) {
        await import(/* webpackChunkName: "i18n/intl-getcanonicallocales/polyfill/[request]" */'@formatjs/intl-getcanonicallocales/polyfill');
    }
}

async function polyfillPluralRules(locale: string) {
    if (typeof Intl === 'undefined' || !('PluralRules' in Intl)) {
        await import(/* webpackChunkName: "i18n/intl-pluralrules/polyfill/[request]" */'@formatjs/intl-pluralrules/polyfill');
        await import(/* webpackChunkName: "i18n/locale-data/[request]" */`@formatjs/intl-pluralrules/locale-data/${locale}`);
    }
}

async function polyfillRelativeTimeFormat(locale: string) {
    if (shouldPolyfillRelativeTimeFormat()) {
        await import(/* webpackChunkName: "i18n/intl-relativetimeformat/polyfill/[request]" */'@formatjs/intl-relativetimeformat/polyfill');
    }

    // @ts-ignore
    if (Intl.RelativeTimeFormat.polyfilled) {
        await import((/* webpackChunkName: "i18n/intl-relativetimeformat/locale-data/[request]" */`@formatjs/intl-relativetimeformat/locale-data/${locale}`));
    }
}

export async function polyfillIntl(locale: string) {

    await polyfillGetCanonicalLocales();
    await polyfillPluralRules(locale);
    await polyfillRelativeTimeFormat(locale);
}
