import { AppState } from '../model';
import { createSelector } from 'reselect';
import {SliderStore} from "./slider.model";

export const getSliderLocale = (state: AppState): string => state.slider.locale;

export const getSliderStore = (state: AppState): SliderStore  => state.slider;

export const getSliderContents = createSelector(
    [getSliderStore],
    (sliderStore) => sliderStore.contents,
);