import { SliderStore } from './slider.model';
import { SLIDER_CHANGE_TRANSLATIONS } from './slider.actions';
import { Reducer } from 'redux';

const INITIAL_STATE: SliderStore = {
    locale: 'en',
    contents: [],
};

export const SliderReducer: Reducer<SliderStore> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SLIDER_CHANGE_TRANSLATIONS: {
            return {
                ...state,
                locale: action.payload.locale,
                contents: action.payload.contents
            };
        }
        default:
            return state;
    }
};