import * as React from 'react';
import './carrier-setup.css';
import { Footer } from '../footer/footer';

class CarrierSetup extends React.PureComponent {

    render() {
        return (
            <main>
                <div className={'carrier-setup'}>
                    <h2>This is carrier setup</h2>
                </div>

                <Footer/>
            </main>
        )
    };
}

export const CarrierSetupComponent = CarrierSetup;