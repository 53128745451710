import { WIDGET_CHANGE_TRANSLATIONS } from './widget.actions';
import { Reducer } from 'redux';
import { WidgetStore } from "./widget.model";

const INITIAL_STATE: WidgetStore = {
    author: 'Doston',
    flipCardContent: [],
    about: {
        title: '',
        left: {
            title: '',
            description: ''
        },
        rightTitle: '',
        right: []
    },
    services: []
};

export const WidgetReducer: Reducer<WidgetStore> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WIDGET_CHANGE_TRANSLATIONS: {
            return {
                ...state,
                author: action.payload.author,
                flipCardContent: action.payload.flipCardContent,
                about: action.payload.about,
                services: action.payload.services
            };
        }
        default:
            return state;
    }
};