import { ContactStore } from './contact.model';
import {CONTACT_SUBMIT_FAILURE, CONTACT_SUBMIT_IN_PROCESS, contactSetSubmissionInProcess} from './contact.actions';
import { Reducer } from 'redux';

const INITIAL_STATE: ContactStore = {
    isSubmissionFailed: false,
    isSubmissionInProcess: false,
};

export const ContactReducer: Reducer<ContactStore> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONTACT_SUBMIT_FAILURE: {
            return {
                ...state,
                isSubmissionFailed: action.payload.isSubmissionFailed
            };
        }
        case CONTACT_SUBMIT_IN_PROCESS: {
            const { payload } = action as ReturnType<typeof contactSetSubmissionInProcess>;
            return {
                ...state,
                isSubmissionInProcess: payload.isSubmissionInProcess,
            };
        }
        default:
            return state;
    }
};