import { Epic, ofType } from 'redux-observable';
import { map, flatMap, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';

import {
    widgetLoadTranslationsRequest as widgetLoadTranslationsRequestAction,
    widgetLoadTranslationsSuccess as widgetLoadTranslationsSuccessAction,
    widgetChangeTranslations as widgetChangeTranslationsAction,
    WIDGET_LOAD_TRANSLATIONS,
    WIDGET_LOAD_TRANSLATIONS_REQUEST,
    WIDGET_LOAD_TRANSLATIONS_SUCCESS,
} from './widget.actions';
import { messages } from '../../locales/messages';
import { errorNotificationAdd } from '../notification/notification.actions';
import { polyfillIntl } from '../../polyfills/intl.polyfill';

export const widgetLoadTranslationsEpic: Epic =
    (action$) =>
        action$.pipe(ofType(WIDGET_LOAD_TRANSLATIONS),
                map(action => {
                    const locale = action.payload.locale;

                    if (messages.includes(locale)) {
                        return widgetLoadTranslationsRequestAction(locale);
                    }

                    return widgetLoadTranslationsRequestAction('en');
                })
            );

export const widgetChangeAfterLoadSuccessEpic: Epic =
    (action$) =>
        action$.pipe(ofType(WIDGET_LOAD_TRANSLATIONS_SUCCESS),
                mergeMap(({ payload }) =>
                    from(polyfillIntl(payload.locale))
                        .pipe(
                            switchMap(() => [widgetChangeTranslationsAction(payload.widgetStoreContent)])
                        )
                )
            );

export const widgetLoadTranslationsRequestEpic: Epic =
    (action$) =>
        action$.pipe(ofType(WIDGET_LOAD_TRANSLATIONS_REQUEST),
            flatMap(({ payload }) =>
                from(import(`./../../src/widget/widget.${payload.locale}.json`))
                .pipe(
                    mergeMap(importedFile => {

                        if (payload.locale === 'en') {
                            return [widgetLoadTranslationsSuccessAction(importedFile)];
                        }
                        return from(import(`./../../src/widget/widget.en.json`))
                            .pipe(
                                map(defaultFile => {
                                    return widgetLoadTranslationsSuccessAction(Object.assign({}, defaultFile, importedFile));
                                }),
                                catchError(() => {
                                    return [widgetLoadTranslationsSuccessAction(importedFile)];
                                })
                            );
                    }),
                    catchError(() => {
                        return [
                            errorNotificationAdd('Sorry, something went wrong. Please try again later.', {})
                        ];
                    })
                )
            ));
