export const messages: string[] = [
    'en',
    'ru'
];

export interface SelectOption {
    value: string;
    label: string;
}

export const languages: SelectOption[] = [
    {
        value: 'en',
        label: 'English'
    },
    {
        value: 'ru',
        label: 'Pусский'
    }
];

export const defaultLocale: {} = {
    value: 'en',
    label: 'English'
}

export const system_feature_slider_enabled = false;
export const system_feature_flipcards_enabled = false;