
export function createLocaleCookie(locale) {
    createCookieThatNeverExpires('locale', locale);
}

export function createCookieThatNeverExpires(cookieName, cookieValue) {
    let date = new Date();
    date.setTime(date.getTime() + (10 * 365 * 24 * 60 * 60));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " + date.toString();
}

export function createCookieInHour(cookieName, cookieValue, hourToExpire) {
    let date = new Date();
    date.setTime(date.getTime() + (hourToExpire * 60 * 60 * 1000));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " + date.toString();
}

export function createCookieInMinutes(cookieName, cookieValue, minutesToExpire) {
    let date = new Date();
    date.setTime(date.getTime() + (minutesToExpire * 60 * 1000));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " + date.toString();
}

export function getLocaleFromCookie() {
    const regex = new RegExp('locale=([^;]+)');
    const cookie = document.cookie.match(regex);
    return !!cookie ? cookie[1] : '';
}