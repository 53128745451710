import * as React from 'react';
import './uniqueness.css';
import { faStackOverflow, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import routeImage from '../../../assets/uniqueness/route-2.jpg';
import truckImage from '../../../assets/uniqueness/truck.jpg';
import {FormattedMessage} from "react-intl";


class Uniqueness extends React.PureComponent {

    public render() {

        return (
            <div className={'uniqueness-container'}>
                <div className={'uniqueness one'}>
                    <div className={'content'}>
                        <div className={'title'}>
                            <h2><FormattedMessage id='uniqueness.1.title'/></h2>
                        </div>
                        <div className={'description'}>
                            <FormattedMessage id='uniqueness.1.description'/>
                        </div>
                    </div>
                    <div className={'image'}>
                        <img src={routeImage} alt={'Trucking with routing'}/>
                    </div>
                </div>
                <div className={'uniqueness two'}>
                    <div className={'content'}>
                        <div className={'title'}>
                            <h2><FormattedMessage id='uniqueness.2.title'/></h2>
                        </div>
                        <div className={'description'}>
                            <h2>What's you average trucking cost per mile?</h2>
                            <FormattedMessage id='uniqueness.2.description'/>
                            <h3>
                                <a href={'#'}>Click here to make a copy for yourself</a>
                            </h3>
                        </div>
                    </div>
                    <div className={'image'}>
                        <img src={truckImage} alt={'Trucking with routing'}/>
                    </div>
                </div>
            </div>
        );
    }
}

export const UniquenessComponent = Uniqueness;