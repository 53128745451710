import * as React from "react";
import './highlight.css';

class TwoColumnHighlight extends React.PureComponent {

    public render() {
        return (
            <div className={'service-highlight'}>
                <div className={'image-hover'}>
                    <div className={'column'}>
                        <div className={'heading'}>
                            <div className={'square-to-circle-animation'}></div>
                            <h3>Our Mission</h3>
                        </div>
                        <div className={'description'}>
                            <b>T-Dispatch:</b> mission is to develop and execute transportation and dispatching services taking your business into account. Our mission is <span style={{color: '#0094ff'}}>to empower small trucking businesses and owner-operator</span> all over the region, that need direction and support in achieving their target loads and incorporate exclusive payment collection, invoice generation, load management services tailored to suit our client’s specific needs.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const HighlightComponent = TwoColumnHighlight;

