import * as React from 'react';
import './equipment.css';
import dryVanTruck from '../../../assets/equipment/Truck_Dry_Van_Icon.svg';
import reeferTruck from '../../../assets/equipment/Truck_Reefer_Icon.svg';
import flatbedTruck from '../../../assets/equipment/Truck_Flatbad_Icon.svg';
import powerOnlyTruck from '../../../assets/equipment/Truck_Power_Only_Icon.svg';
import {
    equipments_type_dry_van_enabled,
    equipments_type_reefer_enabled,
    equipments_type_flatbed_enabled,
    equipments_type_power_only_enabled
} from '../../../configuration/configuration';

class Equipment extends React.PureComponent {

    public render() {
        return (
            <section className={'equipment'}>
                <div className="equipmentWidth">
                    <h2 data-aos="fade-down" className="aos-init aos-animate">TYPES OF EQUIPMENT WE WORK WITH:</h2>
                    <div className="equipmentCards">

                        {equipments_type_dry_van_enabled &&
                            <div className="equipmentCard aos-init aos-animate" data-aos="fade-down">
                                <div className="equipmentCardHeading">DRY VAN</div>
                                <div className="equipmentCardImage">
                                    <img src={dryVanTruck} alt="Dry van"/>
                                </div>
                            </div>
                        }

                        {equipments_type_reefer_enabled &&
                            <div className="equipmentCard aos-init aos-animate" data-aos="fade-down">
                                <div className="equipmentCardHeading">REEFER</div>
                                <div className="equipmentCardImage">
                                    <img src={reeferTruck} alt="Reefer"/>
                                </div>
                            </div>
                        }

                        {equipments_type_flatbed_enabled &&
                            <div className="equipmentCard aos-init aos-animate" data-aos="fade-down">
                                <div className="equipmentCardHeading">FLATBED</div>
                                <div className="equipmentCardImage">
                                    <img src={flatbedTruck} alt="Flatbed"/>
                                </div>
                            </div>
                        }
                        {equipments_type_power_only_enabled &&
                            <div className="equipmentCard aos-init aos-animate" data-aos="fade-down">
                                <div className="equipmentCardHeading">POWER ONLY</div>
                                <div className="equipmentCardImage">
                                    <img src={powerOnlyTruck} alt="Power only"/></div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export const EquipmentComponent = Equipment;