export const LOCALE_LOAD_TRANSLATIONS = '@@locale/LOAD_TRANSLATIONS';
export const LOCALE_LOAD_TRANSLATIONS_REQUEST = '@@locale/LOAD_TRANSLATIONS_REQUEST';
export const LOCALE_LOAD_TRANSLATIONS_SUCCESS = '@@locale/LOAD_TRANSLATIONS_SUCCESS';
export const LOCALE_CHANGE_TRANSLATIONS = '@@locale/CHANGE_TRANSLATIONS';

export const localeLoadTranslations = (locale: string) => {
    return {
        type: LOCALE_LOAD_TRANSLATIONS,
        payload: {
            locale
        }
    };
};

export const localeLoadTranslationsRequest = (locale: string) => {
    return {
        type: LOCALE_LOAD_TRANSLATIONS_REQUEST,
        payload: {
            locale
        }
    };
};

export const localeLoadTranslationsSuccess = (locale: string, messages: {}) => {
    return {
        type: LOCALE_LOAD_TRANSLATIONS_SUCCESS,
        payload: {
            locale,
            messages

        }
    };
};

export const localeChangeTranslations = (locale: string, messages: {}) => {
    return {
        type: LOCALE_CHANGE_TRANSLATIONS,
        payload: {
            locale,
            messages

        }
    };
};