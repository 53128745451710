
export const CONTACT_SUBMIT_FORM = '@@contact/CONTACT_SUBMIT_FORM';
export const CONTACT_SUBMIT_FAILURE = '@@contact/CONTACT_SUBMIT_FAILURE';
export const CONTACT_SUBMIT_IN_PROCESS = '@@contact/CONTACT_SUBMIT_IN_PROCESS';

export const contactSubmitForm = (firstName: string, lastName: string, message: string, phone?: string, email?: string) => {

    return {
        type: CONTACT_SUBMIT_FORM,
        payload: {
            firstName,
            lastName,
            message,
            phone,
            email
        }
    };
};

export const updateContactSubmissionFailure = (isSubmissionFailed: boolean) => {

    return {
        type: CONTACT_SUBMIT_FAILURE,
        payload: {
            isSubmissionFailed
        }
    };
};

export const contactSetSubmissionInProcess = (isSubmissionInProcess: boolean) => {
    return {
        type: CONTACT_SUBMIT_IN_PROCESS,
        payload: {
            isSubmissionInProcess: isSubmissionInProcess
        },
    };
};