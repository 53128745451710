import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store';
import App from './App';
const store = configureStore();

const consoleError = console.error.bind(console);
console.error = (message, ...args) => {
    const errorMessage = message.toString() + '';
    if (errorMessage !== '' && errorMessage.startsWith('Error: [@formatjs/intl Error MISSING_TRANSLATION] Missing message:')) {
        console.warn(errorMessage);
        return;
    }
    consoleError(message, ...args);
};

ReactDOM.render(
    (
        <Provider store={store}>
            <App/>
        </Provider>
    ),
  document.getElementById('root') as HTMLElement
);
