import * as React from 'react';
import './services.css';
import { AppState } from '../../../store/model';
import { getServicesContent } from '../../../store/widget/widget.selectors';
import { connect } from 'react-redux';
import { Services } from '../../../store/widget/widget.model';
import {
    faEnvelopeSquare,
    faHeadset,
    faCity,
    faFileAlt,
    faFileInvoiceDollar,
    faHandshake,
    faBan,
    faRoute,
    faClock
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface InternalState {
    width: number;
    height: number;
}

interface ConnectedState {
    servicesContent: Services[]
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        servicesContent: getServicesContent(state),
    }
}

class ServicesComponentInner extends React.PureComponent<ConnectedState, InternalState> {

    constructor(props: ConnectedState) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    findIcon(iconName: string) {
        if (iconName === 'faHeadset') {
            return <FontAwesomeIcon icon={faHeadset} size='3x' />
        } else if (iconName === 'faClock') {
            return <FontAwesomeIcon icon={faClock} size='3x' />
        } else if (iconName === 'faFileAlt') {
            return <FontAwesomeIcon icon={faFileAlt} size='3x' />
        } else if (iconName === 'faFileInvoiceDollar') {
            return <FontAwesomeIcon icon={faFileInvoiceDollar} size='3x' />
        } else if (iconName === 'faHandshake') {
            return <FontAwesomeIcon icon={faHandshake} size='3x' />
        } else if (iconName === 'faBan') {
            return <FontAwesomeIcon icon={faBan} size='3x' />
        } else if (iconName === 'faRoute') {
            return <FontAwesomeIcon icon={faRoute} size='3x' />
        }

        return <FontAwesomeIcon icon={faEnvelopeSquare} size='3x' />;
    }

    get buildServiceList() {

        const numberOfItems = this.state.width < 1000 ? 6 : 9;
        let counter = 0;
        return (this.props.servicesContent.map((service) => {


            if (counter === numberOfItems) {
                return '';
            }

            counter += 1;
            return (
                <div className={'single-service-box'} key={service.title}>
                    <div className={'single-service'}>
                        <div className={'services-icon'}>
                            {this.findIcon(service.icon)}
                        </div>
                        <div className={'services-text'}>
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                        </div>
                    </div>
                </div>
            )
        }));
    }

    public render() {
        return (
            <div className={'services-container'}>
                <div className={'section-introduction'}>
                    <h2>Why choose T-Dispatch company?</h2>
                </div>
                {this.buildServiceList}
            </div>
        );
    }
}

export const ServicesComponent = connect(mapStateToProps)(ServicesComponentInner);