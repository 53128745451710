import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';

import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { apiEndpointBase } from '../../environment';
import { AnyAction } from 'redux';
import {CONTACT_SUBMIT_FORM, updateContactSubmissionFailure} from "./contact.actions";
import {errorNotificationAdd, successNotificationAdd} from '../notification/notification.actions';

export const contactFormSubmit: Epic<AnyAction> =
    (action$: ActionsObservable<AnyAction>): Observable<AnyAction> =>
        action$.ofType(CONTACT_SUBMIT_FORM)
            .pipe(
                mergeMap(({payload}) => {

                    return ajax({
                        url: `${apiEndpointBase}/p/contact`,
                        method: 'POST',
                        body: payload,
                        crossDomain: true,
                        withCredentials: true,
                        async: false,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).pipe(
                        map(() =>
                            successNotificationAdd('page.contact.success', {})),
                        catchError(() => [
                            updateContactSubmissionFailure(true),
                            errorNotificationAdd('page.contact.error', {})
                        ]),
                    );
                })
            );