import * as React from 'react';
import './foot.css';
import {
    faPhone,
    faEnvelope,
    faMapMarkerAlt,
    faClock
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebook,
    faInstagram,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import logoHorizontal from '../../assets/logo/t-dispatch-logo-white.svg';

export class Footer extends React.PureComponent {

    render() {
        return (
            <div className='pg-footer'>
                <footer>
                    <div className='footerWidth'>
                        <div className='footerLeft'>
                            <a href='/'>
                                <img src={logoHorizontal} alt='T-Dispatch LOGO'/>
                            </a>
                            <div className='socialLinks'>
                                <a target='_blank'
                                   href='https://www.facebook.com/T-Dispatch'>
                                    <FontAwesomeIcon icon={faFacebook} size='3x'/>
                                </a>
                                <a target='_blank'
                                   href='https://www.instagram.com/t-dispatch'>
                                    <FontAwesomeIcon icon={faInstagram} size='3x'/>
                                </a>
                                <a target='_blank' href='https://www.twitter.com/company/t-dispatch'>
                                    <FontAwesomeIcon icon={faTwitter} size='3x'/>
                                </a>
                            </div>
                        </div>
                        <div className='footerCenter'>
                            <p>
                                <span className='iSpan'>
                                <FontAwesomeIcon icon={faPhone} size='2x'/>
                                </span>
                                <span>
                                    <a href='tel:+14046181514'
                                       style={{textDecoration: 'none', color: 'white'}}>
                                        +1 (404) 618-1514
                                    </a>
                                </span>
                            </p>
                            <p>
                                <span className='iSpan'>
                                    <FontAwesomeIcon icon={faEnvelope} size='2x'/>
                                </span>
                                <span>
                                    <a href='mailto:info@t-dispatch.com'
                                       style={{textDecoration: 'none', color: 'white'}}>
                                        info@t-dispatch.com
                                    </a>
                                </span>
                            </p>
                            <p>
                                <span className='iSpan'>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} size='2x'/>
                                </span>
                                <span>
                                    Atlanta City in Georgia USA
                                </span>
                            </p>
                        </div>
                        <div className='footerRight'>
                            <span className={'t-dispatch-button-secondary'}>LET'S CHAT</span>
                            <p>©2022. | All rights reserved.</p>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}