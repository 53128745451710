import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './introduction.css';
import './video-play-button.css';
import './react-video.css';
import './terminal-text-effect.css';

import { SliderContent as SliderContentObject, SliderContentListModel } from '../../../store/slider/slider.model';
import { AppState} from '../../../store/model';
import { getSliderLocale, getSliderContents } from '../../../store/slider/slider.selectors';
import { connect } from 'react-redux';
import Drawer from 'react-drag-drawer';
import { Player } from 'video-react';
import { apiEndpointBase } from '../../../environment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface InternalState {
    isOpen: boolean;
}

interface ConnectedState {
    contents: SliderContentListModel;
    locale: string;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        contents: getSliderContents(state),
        locale: getSliderLocale(state),
    }
}

function consoleText(words, id, colors) {

    if (colors === undefined) colors = ['#fff'];
    let visible = true;
    const con = document.getElementById('console');
    let letterCount = 1;
    let x = 1;
    let waiting = false;
    const target = document.getElementById(id)

    if (target == null || con == null) {
        return;
    }

    target.setAttribute('style', 'color:' + colors[0])
    window.setInterval(function() {

        if (letterCount === 0 && waiting === false) {
            waiting = true;
            target.innerHTML = words[0].substring(0, letterCount)
            window.setTimeout(function() {
                var usedColor = colors.shift();
                colors.push(usedColor);
                var usedWord = words.shift();
                words.push(usedWord);
                x = 1;
                target.setAttribute('style', 'color:' + colors[0])
                letterCount += x;
                waiting = false;
            }, 1000)
        } else if (letterCount === words[0].length + 1 && waiting === false) {
            waiting = true;
            window.setTimeout(function() {
                x = -1;
                letterCount += x;
                waiting = false;
            }, 1000)
        } else if (waiting === false) {
            target.innerHTML = words[0].substring(0, letterCount)
            letterCount += x;
        }
    }, 120)
    window.setInterval(function() {
        if (visible === true) {
            con.className = 'console-underscore hidden'
            visible = false;

        } else {
            con.className = 'console-underscore'

            visible = true;
        }
    }, 400)
}

class IntroductionComponentInner extends React.PureComponent<ConnectedState, InternalState> {

    constructor (props: ConnectedState) {
        super(props);

        this.state = {
            isOpen: false
        }

        this.openModal = this.openModal.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        consoleText(
            [
                'Welcome to T-Dispatch',
                'Dedicated Dispatcher',
                'Dispatch Support 24/7',
                'Best Possible Rates',
                'Back Office Support',
                'There is NO contract obligation',
            ],
            'text',
            [
                '#ff0116',
                '#0fde32',
                '#ffaa00',
                '#00c4ff',
                '#ffeb38',
                '#9dff00',
            ]
        );
    }

    toggle = () => {

        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    openModal () {
        this.setState({isOpen: true})
    }

    get getCleanedSliderContents() {
        var cleanedSliderContents: SliderContentObject[] = [];

        Object.values(this.props.contents).forEach(content => {

            if (content.title !== undefined) {
                cleanedSliderContents.push(content);
            }
        });

        return cleanedSliderContents;
    }

    get getVideoLink() {
        return `${apiEndpointBase}/file/t-dispatch-small.mp4`;
    }

    public render() {

        return (
            <div className={'introduction'}>
                <div className={'image-hover'}>
                    <span
                        className='play-btn'
                        onClick={this.openModal}>

                    </span>
                    <div className='console-container'>
                        <span id='text'></span>
                        <div className='console-underscore' id='console'>&#95;</div>
                    </div>
                    <div className={'introduction-box'}>
                        {/*<p className={'title'}>*/}
                        {/*    <FormattedMessage id='introduction.title.first'/>*/}
                        {/*    <span style={{color: '#2495ED'}}>*/}
                        {/*        <FormattedMessage id='introduction.title.second'/>*/}
                        {/*    </span>*/}
                        {/*</p>*/}
                        <button
                            className={'t-dispatch-button-secondary'}
                            style={{marginRight: '10px'}}
                            onClick={this.openModal}>
                            <FormattedMessage id='page.button.play-video'/>
                        </button>
                        <Link to="/services" className={'t-dispatch-button-primary'}>
                            <FormattedMessage id='page.button.view-services'/>
                        </Link>
                        <Drawer
                            open={this.state.isOpen}
                            onRequestClose={this.toggle}
                            modalElementClass={'modal-video'}
                        >
                            <Player autoPlay={true}>
                                <source src={this.getVideoLink} />
                            </Player>
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    }
}

export const IntroductionComponent = connect(mapStateToProps)(IntroductionComponentInner);