import { SliderContent } from './slider.model';

export const SLIDER_LOAD_TRANSLATIONS = '@@slider/LOAD_SLIDER';
export const SLIDER_LOAD_TRANSLATIONS_REQUEST = '@@slider/LOAD_TRANSLATIONS_REQUEST';
export const SLIDER_LOAD_TRANSLATIONS_SUCCESS = '@@slider/LOAD_TRANSLATIONS_SUCCESS';
export const SLIDER_CHANGE_TRANSLATIONS = '@@slider/CHANGE_TRANSLATIONS';

export const sliderLoadTranslations = (locale: string) => {
    return {
        type: SLIDER_LOAD_TRANSLATIONS,
        payload: {
            locale
        }
    };
};

export const sliderLoadTranslationsRequest = (locale: string) => {
    return {
        type: SLIDER_LOAD_TRANSLATIONS_REQUEST,
        payload: {
            locale
        }
    };
};

export const sliderLoadTranslationsSuccess = (locale: string, contents: SliderContent[]) => {
    return {
        type: SLIDER_LOAD_TRANSLATIONS_SUCCESS,
        payload: {
            locale,
            contents
        }
    };
};

export const sliderChangeTranslations = (locale: string, contents: SliderContent[]) => {
    return {
        type: SLIDER_CHANGE_TRANSLATIONS,
        payload: {
            locale,
            contents
        }
    };
};