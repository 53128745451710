import { NotificationStore } from './notification.model';
import { NOTIFICATION_ADD } from './notification.actions';
import { Reducer } from 'redux';
import { NotificationType } from './notification.type';

const INITIAL_STATE: NotificationStore = {
    message: '',
    parameter: {},
    type: NotificationType.Notice
};

export const NotificationReducer: Reducer<NotificationStore> = (state = INITIAL_STATE, action) =>  {
    switch (action.type) {
        case NOTIFICATION_ADD: {
            return {
                message: action.payload.message,
                parameter: action.payload.parameter,
                type: action.payload.type,
            };
        }
        default:
            return state;
    }
};
