import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { AppState } from '../../store/model';
import { getNotification } from '../../store/notification/notification.selectors';
import { NotificationStore } from '../../store/notification/notification.model';

import 'react-toastify/dist/ReactToastify.css';
import './notification.css';
import { NotificationType } from '../../store/notification/notification.type';


interface ConnectedState {
    notification: NotificationStore;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        notification: getNotification(state)
    };
}

class NotificationComponent extends React.PureComponent<ConnectedState> {

    componentDidUpdate() {
        const messageText = this.getMessage();

        switch (this.props.notification.type) {
            case NotificationType.Success: {
                toast.success(messageText);
                break;
            }
            case NotificationType.Error: {
                toast.error(messageText);
                break;
            }
            default: {
                toast.info(messageText);
            }
        }
    }

    getMessage() {
        const { notification } = this.props;

        return (
            <div>
                <FormattedMessage id={notification.message} values={notification.parameter}/>
            </div>
        );
    }

    render() {
        return (
            <div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={4000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick={false}
                    rtl={false}
                    draggable={false}
                    pauseOnHover={true}
                    transition={Slide}
                    className="notification"
                    bodyClassName="warning"
                />
            </div>
        );
    }
}

export const Notification = connect(mapStateToProps)(NotificationComponent);
