import * as React from 'react';
import './home.css';
import {SliderComponent} from '../widgets/slider/slider';
import {FlipCard} from '../widgets/flipcard/flipCard';
import {
    system_feature_flipcards_enabled,
    system_feature_slider_enabled
} from '../../locales/messages';
import {IntroductionComponent} from '../widgets/introduction/introduction';
import {ServicesComponent} from '../widgets/services/services';
import {UniquenessComponent} from "../widgets/uniqueness/uniqueness";
import {OurOffers} from '../widgets/our-offers/our-offers';
import {InfoComponent} from '../widgets/t-dispatch-info/info';
import { EquipmentComponent } from '../widgets/equipment/equipment';
import { TwoColumnHighlightComponent } from '../widgets/highlights/two-column/two-column-highlight';
import { HighlightComponent } from '../widgets/highlights/hightlight';

import {Footer} from "../footer/footer";


export class HomeComponent extends React.PureComponent {

    render() {
        return (
            <div className={'home'}>

                <IntroductionComponent/>
                {system_feature_slider_enabled &&
                <SliderComponent/>
                }

                {system_feature_flipcards_enabled &&
                <FlipCard/>
                }

                <InfoComponent/>
                <HighlightComponent />
                <UniquenessComponent/>
                <ServicesComponent/>
                <EquipmentComponent />
                <TwoColumnHighlightComponent />
                {/*<OurOffers />*/}

                <Footer/>
            </div>
        );
    }
}