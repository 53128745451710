import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './flipCard.css';
import {FlipCardContent, FlipCardContentListModel} from "../../../store/widget/widget.model";
import {AppState} from "../../../store/model";
import {getFlipCardContents} from "../../../store/widget/widget.selectors";
import {connect} from "react-redux";
import flipCardImage1 from '../../../assets/flip_card_1.png';
import flipCardImage2 from '../../../assets/flip_card_2.png';
import flipCardImage3 from '../../../assets/flip_card_3.png';
import flipCardImage4 from '../../../assets/flip_card_4.png';
import flipCardImage5 from '../../../assets/flip_card_5.png';
import flipCardImage6 from '../../../assets/flip_card_6.png';

interface ConnectedState {
    flipCardContents: FlipCardContentListModel;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        flipCardContents: getFlipCardContents(state),
    }
}

class FlipCardComponent extends React.PureComponent<ConnectedState> {

    get getCleanedSliderContents() {
        var cleanedSliderContents: FlipCardContent[] = [];

        Object.values(this.props.flipCardContents).forEach(content => {

            if (content.frontTitle !== undefined) {
                cleanedSliderContents.push(content);
            }
        });

        return cleanedSliderContents;
    }

    getBackgroundStyle(item) {
        if (item.backgroundImage === "flip_card_1") {
            return {
                backgroundImage: `url(${flipCardImage1})`
            }
        } else if (item.backgroundImage === 'flip_card_2') {
            return {
                backgroundImage: `url(${flipCardImage2})`
            }
        } else if (item.backgroundImage === 'flip_card_3') {
            return {
                backgroundImage: `url(${flipCardImage3})`
            }
        } else if (item.backgroundImage === 'flip_card_4') {
            return {
                backgroundImage: `url(${flipCardImage4})`
            }
        } else if (item.backgroundImage === 'flip_card_5') {
            return {
                backgroundImage: `url(${flipCardImage5})`
            }
        } else if (item.backgroundImage === 'flip_card_6') {
            return {
                backgroundImage: `url(${flipCardImage6})`
            }
        } else {
            return {};
        }
    }

    public render() {


        return (
            <div className={'flip-card-wrapper'}>
                {this.getCleanedSliderContents.map((item, key) => (
                    <div className="flip-card"
                         key={key}>
                        <div className="cols">
                            <div className={'col'}>
                                <div className="container">
                                    <div className="front" style={this.getBackgroundStyle(item)}>
                                        <div className="inner">
                                            <p>{item.frontTitle}</p>
                                            <span>{item.frontDescription}</span>
                                        </div>
                                    </div>
                                    <div className="back" style={this.getBackgroundStyle(item)}>
                                        <div className="inner">
                                            <p>{item.backDescription}</p>
                                            {item.backHighlights !== undefined &&
                                                item.backHighlights.forEach((list, key1) => (
                                                    <ul key={key1}>
                                                        <li>{list}</li>
                                                    </ul>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                }
            </div>
        );
    }
}

export const FlipCard = connect(mapStateToProps)(FlipCardComponent);