import { AppState } from '../model';
import { createSelector } from 'reselect';
import { ContactStore } from "./contact.model";

export const getSliderLocale = (state: AppState): string => state.slider.locale;

export const getContactStore = (state: AppState): ContactStore  => state.contact;

export const isSubmissionFailed = createSelector(
    [getContactStore],
    (contactStore) => contactStore.isSubmissionFailed,
);

export const isSubmissionInProcess = createSelector(
    [getContactStore],
    (contactStore) => contactStore.isSubmissionInProcess,
);