import { NotificationType } from './notification.type';

export const NOTIFICATION_ADD = '@@notification/ADD_NOTIFICATION';

export const notificationAdd = (message: string, parameter: {}, type: NotificationType) => {
    return {
        type: NOTIFICATION_ADD,
        payload: {
            message,
            parameter,
            type
        }
    };
};

export const successNotificationAdd = (message: string, parameter: {}) => {
    return notificationAdd(message, parameter, NotificationType.Success);
};

export const errorNotificationAdd = (message: string, parameter: {}) => {
    return notificationAdd(message, parameter, NotificationType.Error);
};
