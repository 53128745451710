import * as React from 'react';
import { NavLink } from 'react-router-dom';
import './header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, injectIntl, WrappedComponentProps as InjectedIntlProps } from 'react-intl';
import {AppState} from '../../store/model';
import { getLocale } from '../../store/locale/locale.selectors';
import { Dispatch} from 'redux';
import { localeLoadTranslations } from '../../store/locale/locale.actions';
import { connect } from 'react-redux';
import { createLocaleCookie } from '../util/cookieUtils';
import { languages, SelectOption, defaultLocale } from '../../locales/messages';
import Select from 'react-select';
import { successNotificationAdd } from '../../store/notification/notification.actions';
import { sliderLoadTranslations } from '../../store/slider/slider.actions';
import { widgetLoadTranslations } from '../../store/widget/widget.actions';
import logoHorizontal from '../../assets/t-dispatch-logo-2.svg';

interface ConnectedState {
    locale: string;
}

interface ConnectedDispatch {
    loadLocaleTranslations: (locale: string) => void;
    showSuccessNotification: (message: string) => void;
    loadSliderTranslations: (locale: string) => void;
    loadWidgetTranslations: (locale: string) => void;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        locale: getLocale(state),
    };
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        loadLocaleTranslations: (locale) => dispatch(localeLoadTranslations(locale)),
        showSuccessNotification: (message) => dispatch(successNotificationAdd(message, {})),
        loadSliderTranslations: locale => dispatch(sliderLoadTranslations(locale)),
        loadWidgetTranslations: locale => dispatch(widgetLoadTranslations(locale)),
    };
};


interface State {
    isMobileMenuOpen: boolean;
}

export class HeaderComponent extends React.PureComponent<ConnectedState & ConnectedDispatch & InjectedIntlProps, State> {

    constructor(props: ConnectedDispatch & ConnectedState & InjectedIntlProps) {
        super(props);

        this.state = {
            isMobileMenuOpen: false,
        };

        this.onLanguageChange = this.onLanguageChange.bind(this);
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    }

    onLanguageChange(option: SelectOption) {
        createLocaleCookie(option.value);
        this.props.loadLocaleTranslations(option.value);
        this.props.loadSliderTranslations(option.value);
        this.props.loadWidgetTranslations(option.value);

        setTimeout(() => {
            this.props.showSuccessNotification('notification.locale.change.success');
        }, 3000);
    }

    toggleMobileMenu() {
        const currentState = this.state.isMobileMenuOpen;
        this.setState({ isMobileMenuOpen: !currentState });
    };

    get selectOptions() {
        return languages.map((language) => {
            return (
                <option value={language.value} key={language.value} className={'option'}>{language.label}</option>
                )
        });
    }

    get defaultLocale() {
        let value;

        languages.forEach(language => {
            if (language.value === this.props.locale) {
                value = language;
            }
        });

        if (value === undefined || value === null) {
            value = defaultLocale;
        }

        return value;
    }

    get languageSwitcher() {
        return (
            <select
                value={this.props.locale}
                id={'language-selector'}
            >
                {this.selectOptions}
            </select>
        );
    }

    render() {

        console.log('IsOpen: ' + this.state.isMobileMenuOpen);
        return (
            <div>
                <div className='top-header-top'>
                    <div className='column left'>
                        <a id={'welcome'} href='https://www.t-dispatch.com'>Welcome to T-Dispatch</a>
                    </div>
                    <div className='column right'>
                        <a id={'email'} href='mailto:info@t-dispatch.com'>
                            <FontAwesomeIcon icon={faEnvelopeSquare} size='1x' />  info@t-dispatch.com
                        </a>
                        <a id={'phone'} href='tel:+14046181514'><FontAwesomeIcon icon={faPhone} size='1x' /> +1 (404) 618-1514 </a>
                    </div>
                </div>

                <header>
                    <NavLink to={'/'} exact>
                        <img src={logoHorizontal} alt={'Logo'} id={'brand'}/>
                    </NavLink>
                    <nav>
                        <ul>
                            <li>
                                <NavLink to={'/'} className='nav-link' exact>
                                    <FormattedMessage id='navbar.name.home'/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/about'} className='nav-link' exact>
                                    <FormattedMessage id='navbar.name.about'/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/services'} className='nav-link' exact>
                                    <FormattedMessage id='navbar.name.services'/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'/contact'} className='nav-link' exact>
                                    <FormattedMessage id='navbar.name.contact'/>
                                </NavLink>
                            </li>
                            <li id='carrier-setup'>
                                <NavLink to={'/carrier-setup'} id='carrier-setup'>
                                    <FormattedMessage id='page.button.carrier-setup'/>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>

                    {/*    <Select*/}
                    {/*        value={this.defaultLocale}*/}
                    {/*        id={'language-selector'}*/}
                    {/*        options={languages}*/}
                    {/*        onChange={this.onLanguageChange}*/}
                    {/*    />*/}
                    <div id='mobile-menu-container'
                         className={this.state.isMobileMenuOpen ? 'open': 'closed'}
                         onClick={this.toggleMobileMenu} >
                        <div className='bar1'></div>
                        <div className='bar2'></div>
                        <div className='bar3'></div>
                        <div className='mobile-menu'>
                                <NavLink to={'/'} className='nav-link' exact>
                                    <FormattedMessage id='navbar.name.home'/>
                                </NavLink>
                                <NavLink to={'/about'} className='nav-link' exact>
                                    <FormattedMessage id='navbar.name.about'/>
                                </NavLink>
                                <NavLink to={'/services'} className='nav-link' exact>
                                    <FormattedMessage id='navbar.name.services'/>
                                </NavLink>
                                <NavLink to={'/contact'} className='nav-link' exact>
                                    <FormattedMessage id='navbar.name.contact'/>
                                </NavLink>
                            <hr />
                            <NavLink to={'/contact'} className='nav-link' exact>
                                <FormattedMessage id='navbar.name.contact'/>
                            </NavLink>
                            <NavLink to={'/carrier-setup'} id='carrier-setup'>
                                <FormattedMessage id='page.button.carrier-setup'/>
                            </NavLink>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

export const Header = injectIntl(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent));