import { AppState } from '../model';
import { createSelector } from 'reselect';
import { WidgetStore } from "./widget.model";

export const getWidgetAuthor = (state: AppState): string => state.widget.author;

export const getWidgetStore = (state: AppState): WidgetStore  => state.widget;

export const getFlipCardContents = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.flipCardContent,
);

export const getAboutContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.about,
);

export const getServicesContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.services,
);