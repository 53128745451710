import * as React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Dispatch } from 'redux';
import { AppState } from '../../store/model';
import { getLocale, getMessages } from '../../store/locale/locale.selectors';
import { localeLoadTranslations } from '../../store/locale/locale.actions';
import { createCookieThatNeverExpires } from '../util/cookieUtils';
import {sliderLoadTranslations} from "../../store/slider/slider.actions";
import {widgetLoadTranslations} from "../../store/widget/widget.actions";
import { defaultLocale } from "../../locales/messages";


interface ConnectedState {
    translationLocale: string;
    translationMessages: {};
}

interface ConnectedDispatch {
    loadLocaleTranslations: (locale: string) => void;
    loadSliderTranslations: (locale: string) => void;
    loadWidgetTranslations: (locale: string) => void;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        translationLocale: getLocale(state),
        translationMessages: getMessages(state),
    };
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        loadLocaleTranslations: (locale) => dispatch(localeLoadTranslations(locale)),
        loadSliderTranslations: locale => dispatch(sliderLoadTranslations(locale)),
        loadWidgetTranslations: locale => dispatch(widgetLoadTranslations(locale)),
    };
};

interface Props {
    children: JSX.Element[];
}

export class LocaleProviderComponent extends React.PureComponent<ConnectedState & ConnectedDispatch & Props> {

    componentDidMount() {
        let locale = this.localeFromCookie;

        console.log('Local from cookie: ' + locale);

        this.props.loadLocaleTranslations(locale);
        this.props.loadSliderTranslations(locale);
        this.props.loadWidgetTranslations(locale);
    }


    get localeFromCookie() {

        const regex = new RegExp('locale=([^;]+)');
        const cookie = document.cookie.match(regex);
        let locale = !!cookie ? cookie[1] : '';

        if (locale === '') {
            createCookieThatNeverExpires('locale', defaultLocale['value']);
            return defaultLocale['value'];
        } else {
            return locale;
        }
    }

    render() {
        return (
            <IntlProvider
                key={this.props.translationLocale}
                locale={this.props.translationLocale}
                messages={this.props.translationMessages}
                textComponent='span'
            >
                <>
                {this.props.children}
                </>
            </IntlProvider>
        );
    }
}

export const LocaleProvider = connect(mapStateToProps, mapDispatchToProps)(LocaleProviderComponent);