import { createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { AppState } from './store/model';
import { rootReducer } from './store/reducer';
import { rootEpic } from './store/epic';

const composeEnhancers = composeWithDevTools({
    maxAge: 500
});

const epicMiddleware = createEpicMiddleware();

export function configureStore(): Store<AppState | undefined> {
    const store = createStore(
        rootReducer,
        undefined,
        composeEnhancers(applyMiddleware(epicMiddleware))
    );

    epicMiddleware.run(combineEpics(...rootEpic));

    return store;
}
