import { LocaleStore } from './locale.model';
import { LOCALE_CHANGE_TRANSLATIONS } from './locale.actions';
import { Reducer } from 'redux';

const INITIAL_STATE: LocaleStore = {
    locale: 'en',
    messages: {},
};

export const LocaleReducer: Reducer<LocaleStore> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOCALE_CHANGE_TRANSLATIONS: {
            return {
                ...state,
                locale: action.payload.locale,
                messages: action.payload.messages
            };
        }
        default:
            return state;
    }
};