import * as React from 'react';
import './about.css';
import { AboutContent } from '../../store/widget/widget.model';
import { AppState } from '../../store/model';
import { getAboutContent } from '../../store/widget/widget.selectors';
import {connect} from 'react-redux';
import {Footer} from '../footer/footer';
import ceoPhoto from '../../assets/about/photo_2022-01-28_22-43-50.jpg';
import {HighlightComponent} from "../widgets/highlights/hightlight";
import {faHeadset} from "@fortawesome/free-solid-svg-icons";
import { BiBadgeCheck } from 'react-icons/bi';
import { MdOutlineCenterFocusWeak } from 'react-icons/md';
import { MdSupportAgent } from 'react-icons/md';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ConnectedState {
    aboutContent: AboutContent;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        aboutContent: getAboutContent(state),
    }
}

class AboutComponent extends React.PureComponent<ConnectedState> {

    public render() {
        
        return (
            <main>
                <div className={'about-page'}>
                    <div className={'introduction'}>
                        <div className={'image-holder'}>
                            <div className={'text'}>
                                <div className={'square-to-circle-animation'}/>
                                <h1>We are here to make you rich</h1>
                            </div>
                        </div>
                    </div>
                    <div className={'about-ceo'}>
                        <div>
                            <div className={'image'}>
                                <div className={'image-holder'}>
                                    <div className={'border-line-one'}>
                                        <div className={'border-line-two'}>
                                            <img src={ceoPhoto} alt={'CEO of T-Dispatch'}/>
                                        </div>
                                    </div>
                                </div>
                                <h3>Rustam Y.</h3>
                                <h4>CEO</h4>
                            </div>
                            <div className={'text'}>
                                <div className={'heading'}>
                                    <h3>MESSAGE FROM OUR CEO</h3>
                                </div>
                                <div className={'description'}>
                                    My experience in this industry has taught me that to best serve my customers, a team is required. I take great pride in knowing that T-Dispatch LLC only employs hardworking and dedicated individuals who are always willing to go above and beyond to ensure we’re delivering what the client is asking for. Whether on the road or in the office, our team of experts work diligently to make sure that our service to you is smooth and effortless.

                                    <br/><br/>
                                    Our T-Dispatch team believes that the key to being an amazing dispatcher is communication and organization. Our success comes from the quality communication we offer to each client, allowing us to build a strong mutual business relationship.
                                    T-Dispatch LLC is a well-established truck dispatching company that will forever aim to satisfy its customers by always performing with integrity, hard work and remaining committed to providing all our customers with the best Trucking Dispatch Services.

                                    <br/><br/>
                                    Sincerely,<br/>Rustam Y.<br/>Chief Executive Officer
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HighlightComponent />
                <div className={'about-values'}>
                    <h2>Our Values</h2>
                    <div className={'single-about-box'}>
                        <div className={'single-service'}>
                            <div className={'services-icon'}>
                                <BiBadgeCheck size={'50px'}/>
                            </div>
                            <div className={'services-text'}>
                                <h3>Integrity</h3>
                                <p>
                                    We stand by our service and commitment to clients. We build long-term relationships with all our clients. Therefore, the quality of our products is the priority for us. Keeping high standards, we prove our intentions and deliver foremost solutions to assist our customers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={'single-about-box'}>
                        <div className={'single-service'}>
                            <div className={'services-icon'}>
                                <MdOutlineCenterFocusWeak size={'50px'}/>
                            </div>
                            <div className={'services-text'}>
                                <h3>Employee Focused</h3>
                                <p>
                                    We hire the best talent in the industry to support our clients. They have the experience as well as the educational background and/or continuous training to add value to our clients. Most importantly, they are always focused on satisfying the client.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={'single-about-box'}>
                        <div className={'single-service'}>
                            <div className={'services-icon'}>
                                <MdSupportAgent size={'50px'}/>
                            </div>
                            <div className={'services-text'}>
                                <h3>Customer Service</h3>
                                <p>
                                    Our clients are the key to our success. We go above and beyond to ensure we’re delivering what the client is asking for. We’re here to serve as a company.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer/>
            </main>
        );
    }
}

export const About = connect(mapStateToProps)(AboutComponent);