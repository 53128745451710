import { combineReducers } from 'redux';
import { LocaleReducer } from './locale/locale.reducer';
import { NotificationReducer } from './notification/notification.reducer';
import { AppState } from './model';
import { SliderReducer } from './slider/slider.reducer';
import { WidgetReducer } from './widget/widget.reducer';
import { ContactReducer } from './contact/contact.reducer';


export const reducers = {
    locale: LocaleReducer,
    notification: NotificationReducer,
    slider: SliderReducer,
    widget: WidgetReducer,
    contact: ContactReducer,
};

export const rootReducer = combineReducers<AppState>(reducers);
