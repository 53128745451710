import React from 'react';
import './App.css';
import './container/t-dispatch.css';
import './container/animation.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Header } from './container/header/header';
import { HomeComponent } from './container/home/home';
import { ContactComponent } from './container/contact/contact';
import { About } from './container/about/about';
import { Footer } from './container/footer/footer';
import { Notification } from './container/notification/notification';
import { LocaleProvider } from './container/locale/localeProvider';
import { ServicesPageComponent } from './container/services/services';
import { CarrierSetupComponent } from './container/carrier-setup/carrierSetup';

class App extends React.PureComponent {

    render() {
        return (
            <div>
                <LocaleProvider>
                    <Router>
                        <div>
                            <Header />
                            <Switch>
                                <Route path='/' component={ HomeComponent } exact/>
                                {/*<Route path='/hamdos-fontend' component={ HomeComponent } exact/>*/}
                                <Route path='/contact' component={ ContactComponent } />
                                <Route path='/services' component={ ServicesPageComponent } />
                                <Route path='/about' component={ About } />
                                <Route path='/carrier-setup' component={ CarrierSetupComponent } />
                            </Switch>
                            {this.props.children}
                        </div>
                    </Router>
                    <Notification />
                </LocaleProvider>
            </div>
        )
    }
}

export default App;
