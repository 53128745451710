import * as React from 'react';
import Slider  from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './slider.css';
import {SliderContent as SliderContentObject, SliderContentListModel} from '../../../store/slider/slider.model';
import {AppState} from '../../../store/model';
import {getSliderLocale, getSliderContents} from '../../../store/slider/slider.selectors';
import {connect} from 'react-redux';


interface ConnectedState {
    contents: SliderContentListModel;
    locale: string;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        contents: getSliderContents(state),
        locale: getSliderLocale(state),
    }
}

class SliderComponentInner extends React.PureComponent<ConnectedState> {


    get getCleanedSliderContents() {
        var cleanedSliderContents: SliderContentObject[] = [];

        Object.values(this.props.contents).forEach(content => {

            if (content.title !== undefined) {
                cleanedSliderContents.push(content);
            }
        });

        return cleanedSliderContents;
    }


    public render() {

        return (
            <div>
                {/*<Slider autoplay={4000}>*/}
                <Slider >
                    {this.getCleanedSliderContents.map((item, key) => (
                        <div
                            key={key}
                            style={{
                                backgroundImage: `url('${item.image}')`,
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                            }}
                            className={'slide'}
                        >
                            <div key={key}
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.3)',
                                width: '100%',
                                height: '100%',
                            }}>
                                <div className='center'>
                                    <h1 style={{color: item.textColor}}>{item.title}</h1>
                                    <p style={{color: item.textColor}}>{item.description}</p>
                                    <button style={{color: "black", fontWeight: "bold"}}>{item.button}</button>
                                </div>
                            </div>
                        </div>

                    ))}
                </Slider>
            </div>
        );
    }
}

export const SliderComponent = connect(mapStateToProps)(SliderComponentInner);