import * as React from 'react';
import './services.css';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Footer} from "../footer/footer";
import dryVanTruck from '../..//assets/equipment/Truck_Dry_Van_Icon.svg';
import powerOnlyTruck from '../../assets/equipment/Truck_Power_Only_Icon.svg';

class Services extends React.PureComponent {

    public render() {
        return (
            <main className={'service-page'}>
                <section className={'introduction'}>
                    <div className={'image-hover'}>
                        <div className={'text'}>
                            <div className="dots-bars-7"></div>
                            <h1>Our quality makes difference</h1>
                        </div>
                    </div>
                </section>
                <section className={'introduction-text-section'}>
                    <div className={'text'}>
                        <p>
                            We assist you to find the best possible load for you. ANY TIME ANYWHERE in the USA. We also help small trucking companies to scale up their business. We have direct connections with reputed shippers and brokers. Our dispatchers have experience in identifying and negotiating best rates for you. We don’t work with contract basis and there is no forced dispatch.
                        </p>
                    </div>
                </section>
                <section className="services">
                    <div className="servicesWidth">
                        <table className="main">
                            <thead>
                            <tr>
                                <th>
                                    <div className="choosePackage">
                                        <p>CHOOSE
                                            <br/>YOUR
                                            <br/>PACKAGE
                                        </p>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Personal dispatcher with 24 / 7 support</td>
                            </tr>
                            <tr>
                                <td>Broker Credit Checks</td>
                            </tr>
                            <tr>
                                <td>Carrier-Broker Setups</td>
                            </tr>
                            <tr>
                                <td>Load Search/Booking</td>
                            </tr>
                            <tr>
                                <td>Load Rate Negotiations</td>
                            </tr>
                            <tr>
                                <td>Market Trend Analysis</td>
                            </tr>
                            <tr>
                                <td>Trip Coordination</td>
                            </tr>
                            <tr>
                                <td>All Driver Assistance</td>
                            </tr>
                            <tr>
                                <td>Consistent High Paying Freight</td>
                            </tr>
                            <tr>
                                <td>Provide assistance with TONUs and detention</td>
                            </tr>
                            <tr>
                                <td>Checking Weather Updates</td>
                            </tr>
                            <tr>
                                <td>Weekly Reports</td>
                            </tr>
                            <tr>
                                <td>24/7 Roadside Assistance in case of Breakdown</td>
                            </tr>
                            <tr>
                                <td>Develop Dedicated Lanes</td>
                            </tr>
                            <tr>
                                <td>Keep track of all your loads' miles</td>
                            </tr>
                            <tr>
                                <td>Create all your invoices and collect payments</td>
                            </tr>
                            </tbody>

                        </table>

                        <table className="standard">
                            <thead>
                            <tr>
                                <th>
                                    <div className="standardPackage">
                                        <div className="tableHeadingText">
                                            <p className="tableHeadingTitle">STANDARD</p>
                                            <p className="tableHeadingPercentage">2.9%</p>
                                            <p className="tableHeadingRevenue">rate per load</p>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faTimes} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faTimes} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faTimes} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faTimes} size='1x' /></td>
                            </tr>
                            </tbody>

                        </table>

                        <table className="pro">
                            <thead>
                            <tr>
                                <th>
                                    <div className="proPackage">
                                        <div className="tableHeadingText">
                                            <p className="tableHeadingTitle">PRO</p>
                                            <p className="tableHeadingPercentage">3.99%</p>
                                            <p className="tableHeadingRevenue">rate per load</p>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={faCheck} size='1x' /></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <section className={'equipments'}>
                    <div className={'equipment-type'}>
                        <h2>Types of Equipment Dispatched</h2>
                        <div className={'equipment-card'}>
                            <div className={'image-holder'}>
                                <img src={dryVanTruck} alt={'Dry Van Truck type'} />
                            </div>
                            <div className={'text'}>
                                <h3>DRY VAN</h3>
                                <p>
                                    Dry vans provide a huge benefit to clients across the United States, keeping their goods and safe and sound while they are out on the roads.
                                </p>
                            </div>
                        </div>
                        <div className={'equipment-card'}>
                            <div className={'image-holder'}>
                                <img src={powerOnlyTruck} alt={'Power Only Truck type'} />
                            </div>
                            <div className={'text'}>
                                <h3>Power Only</h3>
                                <p>
                                    Power only trucking services involve the use of an independent driver or carrier tractor in the transport of a trailer. It is a cost-effective way for brokers to efficiently secure end-to-end logistics for loads.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </main>
        );
    }
}

export const ServicesPageComponent = Services;